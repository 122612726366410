import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {Menu} from "@mui/icons-material";
import {Link} from "react-router-dom";

export default function NavBar(
  {toggleLeftSideDrawer, setShowBookingModal, showBookingModal, showInfoModal, setShowInfoModal, showMembershipModal, setShowMembershipModal}: any) {
  return (
    <Box component="div" sx={{ flexGrow: 1 }} className="navBar" >
      <AppBar position="fixed" className='app-bar'>
        <Toolbar>
          {/*<div className={`hamburger-button`}>*/}
          {/*  <Menu className="pointer" style={{ color: "white", margin: "4px 10px 0 -5px" }} onClick={(event) => {toggleLeftSideDrawer(event)}}/>*/}
          {/*</div>*/}
          <Typography className="main-title" variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <a href={window.origin}>
              {process.env.REACT_APP_NAV_TITLE}
            </a>
          </Typography>
        </Toolbar>
      </AppBar>
    </Box>
  )
}

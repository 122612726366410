import React, { useState, useRef, useEffect } from 'react';

const CameraComponent: React.FC = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isCameraOn, setIsCameraOn] = useState(false);

  const startCamera = async () => {
    try {
      const constraints = {
        video: {
          facingMode: "environment"  // Request the rear camera
        }
      };
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
      setIsCameraOn(true);
    } catch (error) {
      console.error('Error accessing the camera', error);
      // Handle errors (like user denying access to the camera)
    }
  };

  useEffect(() => {
    return () => {
      if (isCameraOn && videoRef.current && videoRef.current.srcObject) {
        const tracks = (videoRef.current.srcObject as MediaStream).getTracks();
        tracks.forEach(track => track.stop());
      }
    };
  }, [isCameraOn]);

  return (
    <div>
      <button onClick={startCamera}>Start Camera</button>
      <video ref={videoRef} autoPlay={true} style={{ display: isCameraOn ? 'block' : 'none', maxHeight: '20vh', width: '90vw' }} />
    </div>
  );
};

export default CameraComponent;

import React, {useEffect, useState} from 'react';
import { useLocation } from 'react-router-dom';
import {
  Route, Routes
} from "react-router-dom";
import './App.scss';
import NavBar from "./components/NavBar";
import {CameraAltOutlined, ChevronLeft, ChevronRight, InfoOutlined, Menu, ZoomIn, ZoomOut} from "@mui/icons-material";
import PhotoViewer from "./components/PhotoViewer";
import InfoModal from "./components/InfoModal";
import MenuModal from "./components/MenuModal";
import LeftSideDrawer from "./components/LeftSideDrawer";
import HomeScreen from "./components/HomeScreen";
import StoryScreen from "./components/StoryScreen";
import {Box, Container, CssBaseline, Grid, Link, Typography} from "@mui/material";
import EventsModal from "./components/EventsModal";
import QuestionnaireScreen from "./components/QuestionnaireScreen";
// import BookingModal from "./components/BookingModal";
import CookieConsent from "react-cookie-consent";
const EMAIL='';
const RECEPTION_EMAIL='';

function App() {
  const [showImages, setShowImages] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showMembershipModal, setShowMembershipModal] = useState(false);
  const [showEventsModal, setShowEventsModal] = useState(false);
  const [showMenuModal, setShowMenuModal] = useState(false);
  const [showBookingModal, setShowBookingModal] = useState(false);
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const toggleLeftSideDrawer = (event: React.KeyboardEvent | React.MouseEvent) => {
      if (event.type === 'keydown' && (
        (event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift'))
      {
        return;
      }
      setDrawerOpen(!drawerOpen);
  };

  const openCredits = () => {
    setShowInfoModal(true)
  }

  const location = useLocation();
  useEffect(() => {
    if (location.pathname.includes('events')) {
      setShowEventsModal(true);
    } else if (location.pathname.includes('info')) {
      setShowInfoModal(true);
    } else if (location.pathname.includes('menus')) {
      setShowMenuModal(true);
    }
  }, [location]);

  return (
    <div className="App">
      <CssBaseline />

      <NavBar
        showInfoModal={showInfoModal}
        setShowInfoModal={setShowInfoModal}
        showMembershipModal={showMembershipModal}
        setShowMembershipModal={setShowMembershipModal}
        toggleLeftSideDrawer={toggleLeftSideDrawer}
        showBookingModal={showBookingModal}
        setShowBookingModal={setShowBookingModal}
      />

      <InfoModal showInfoModal={showInfoModal} setShowInfoModal={setShowInfoModal} EMAIL={EMAIL} />
      {/*<BookingModal showBookingModal={showBookingModal} setShowBookingModal={setShowBookingModal} />*/}
      <PhotoViewer showImages={showImages} />

      {/*<LeftSideDrawer*/}
      {/*  drawerOpen={drawerOpen}*/}
      {/*  toggleLeftSideDrawer={toggleLeftSideDrawer}*/}
      {/*  setShowImages={setShowImages}*/}
      {/*  setShowInfoModal={setShowInfoModal}*/}
      {/*  setShowEventsModal={setShowEventsModal}*/}
      {/*  setShowMenuModal={setShowMenuModal}*/}
      {/*  showMenuModal={showMenuModal}*/}
      {/*/>*/}

      <Routes>
        <Route
          key={'home'}
          path="/"
          element={
            <QuestionnaireScreen toggleLeftSideDrawer={toggleLeftSideDrawer}/>
          }
        />
        {/*<Route*/}
        {/*  key={'questionnaire'}*/}
        {/*  path="/questionnaire"*/}
        {/*  element={*/}
        {/*    <QuestionnaireScreen toggleLeftSideDrawer={toggleLeftSideDrawer}/>*/}
        {/*  }*/}
        {/*/>*/}

        <Route
          path="*"
          element={
            <main style={{ padding: "1rem" }}>
              <p>There's nothing here!</p>
            </main>
          }
        />
      </Routes>

      {/*<div className="buttons-container">*/}
      {/*  <InfoOutlined className="pointer" style={{ color: "white", margin: "0 4px" }} onClick={() => {setShowInfoModal(!showInfoModal)}}/>*/}

      {/*  <div className="pointer" onClick={() => {setShowImages(!showImages)}}>*/}
      {/*    <CameraAltOutlined  style={{ color: "white", margin: "0 4px" }} />*/}
      {/*    { showImages && (*/}
      {/*      <ChevronRight style={{ color: "white", margin: "0 4px" }} />*/}
      {/*    )}*/}
      {/*    { !showImages && (*/}
      {/*      <ChevronLeft style={{ color: "white", margin: "0 4px" }} />*/}
      {/*    )}*/}
      {/*    </div>*/}
      {/*</div>*/}


      <div className="footer">

        <Container className="footer-container">
          <Box component="div" sx={{ flexGrow: 1 }}>

            {/*<Grid container spacing={2}>*/}
            {/*  <Grid item xs={12}>*/}
            {/*    <Typography*/}
            {/*      variant="h5"*/}
            {/*      component="p"*/}
            {/*      sx={{ flexGrow: 1 }}*/}
            {/*      className="footer__link"*/}
            {/*      onClick={() => {setShowInfoModal(!showInfoModal)}}*/}
            {/*    >*/}
            {/*      Info*/}
            {/*    </Typography>*/}
            {/*  </Grid>*/}
              {/*<Grid item xs={12}>*/}
              {/*  <Typography*/}
              {/*    variant="h5"*/}
              {/*    component="div"*/}
              {/*    sx={{ flexGrow: 1 }}*/}
              {/*    className="footer__text"*/}
              {/*  >*/}
              {/*    <Link className="footer__link" href="" target="_blank" underline="none">*/}
              {/*      &nbsp;Github*/}
              {/*    </Link>*/}
              {/*  </Typography>*/}
              {/*</Grid>*/}

              {/*<Grid item xs={12}>*/}
              {/*  <Typography*/}
              {/*    variant="h5"*/}
              {/*    component="div"*/}
              {/*    sx={{ flexGrow: 1 }}*/}
              {/*    className="footer__text"*/}
              {/*  >*/}
              {/*    <Link className="footer__link" href="" target="_blank" underline="none">*/}
              {/*      &nbsp;Twitter*/}
              {/*    </Link>*/}
              {/*  </Typography>*/}
              {/*</Grid>*/}

              {/*<Grid item xs={12}>*/}
              {/*  <Typography*/}
              {/*    variant="h5"*/}
              {/*    component="div"*/}
              {/*    sx={{ flexGrow: 1 }}*/}
              {/*    className="footer__text"*/}
              {/*  >*/}
              {/*    <Link className="footer__link" href="" target="_blank" underline="none">*/}
              {/*      &nbsp;Youtube*/}
              {/*    </Link>*/}
              {/*  </Typography>*/}
              {/*</Grid>*/}


            {/*</Grid>*/}

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography
                  variant="h5"
                  component="div"
                  sx={{ flexGrow: 1 }}
                  className="footer__text"
                >
                  {/*<p className="" style={{float:'left', cursor: 'pointer'}} onClick={() => {openCredits()}}>Credits<span*/}
                  {/*  className=""></span>*/}
                  {/*</p>*/}
                  {/*<p style={{float:'left'}}>Cookies used for<br/>Google Analytics</p>*/}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="h5"
                  component="div"
                  sx={{ flexGrow: 1 }}
                  className="footer__text"
                >
                  <p className="footer__copyright"><a target="_blank" href="https://unegma.com">made by <span style={{textDecoration: 'underline'}}>unegma</span><span
                    className="unegma-blue">.</span></a><br/>
                    {/*&copy; {new Date().getFullYear()} Starling.Red*/}
                  </p>
                </Typography>
              </Grid>
            </Grid>
          </Box>

        </Container>

      </div>

      <CookieConsent
        location="bottom"
        buttonText="Ok"
        cookieName="googleAnalytics"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        This website uses cookies for tracking page views.
        {/*<span style={{ fontSize: "10px" }}>This bit of text is smaller :O</span>*/}
      </CookieConsent>
    </div>
  );
}

export default App;

import React, {Suspense, useEffect, useRef, useState} from "react";
import Button from '@mui/material/Button';
import ButtonGroup from "@mui/material/ButtonGroup";
import {
  FormControl,
  FormControlLabel,
  FormLabel, InputLabel,
  LinearProgress, MenuItem,
  Radio,
  RadioGroup,
  TextField
} from "@mui/material";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import CameraComponent from "./CameraComponent";
export default function QuestionnaireScreen({toggleLeftSideDrawer}: {toggleLeftSideDrawer: any}) {

  const TOTAL_QUESTIONS = 6; // todo does this include the first info page?

  const answersObject = {
    // date: '',
    // 1
    issueType: '',
    location: '',
    // 2
    urgency: '',
    // 3
    description: '',
    // 4
    reporterName: '',
    reporterContact: '',
  }

  const [progress, setProgress] = React.useState(4); // todo can this be replaced with the number of questions?
  const [section, setSection] = useState(1);
  const [answers, setAnswers] = useState(answersObject);
  const [issueType, setIssueType] = useState('');
  const [agent, setAgent] = useState('');
  const [email, setEmail] = useState('');
  const [validEmailAddress, setValidEmailAddress] = useState(false);
  const [localEmail, setLocalEmail] = useState("");
  const [agentEmail, setAgentEmail] = useState('');
  const [validAgentEmailAddress, setValidAgentEmailAddress] = useState(false);
  const [localAgentEmail, setLocalAgentEmail] = useState("");

  const [date, setDate] = useState('');
  const [reporter, setReporter] = useState('');
  const [reporterContact, setReporterContact] = useState('');
  // const [issueType, setIssueType] = useState('');
  const [description, setDescription] = useState('');
  const [location, setLocation] = useState('');
  const [urgency, setUrgency] = useState('');

  const postData = async (theData: any): Promise<any> => {
    try {
      // console.log(`${process.env.REACT_APP_API_ENDPOINT}/membership`);
      let data = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/feedback`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(theData)
      });

      // the response will then come back as promise, the data of which will need to be accessed as such:
      data = await data.json();
      // console.log(data);
      // @ts-ignore
      return data;
    } catch (err) {
      console.log(err);
      return {};
    }
  }

  const beginSend = () => {
    // if (email == "") {
    //   setConsoleState('error');
    //   setConsoleMessage(`Please enter a valid email address.`);
    //   return;
    // }

    // setSubmitting(true);
    // setConsoleState('success');
    // setConsoleMessage(`Submitting..`);

    postData(answers).then((data: any) => {
      // console.log(data);
      if (data.message === 'success') {
        // todo get receipt?
        // setConsoleState('success');
        // setConsoleMessage(`Form submitted, we will be in touch shortly.`);
        // setSubmitting(false) // don't let user submit again
        console.log(data)
      } else {
        // setSubmitting(false);
        throw new Error('Error Submitting Form');
      }
    }).catch((error: any) => {
      // setSubmitting(false);
      console.log(error);
      // setConsoleState('error');
      // setConsoleMessage(`Error submitting form, please drop us an email at ${EMAIL}.`);
    })
  }

  const changeToSection = (changeTo: number) => {
    setSection(changeTo);
    setProgress(changeTo/TOTAL_QUESTIONS*100)
  }

  /**
   * https://stackoverflow.com/questions/46155/how-can-i-validate-an-email-address-in-javascript
   * @param email
   */
  function validEmail(email: string) {
    // var re = /\S+@\S+\.\S+/;
    // /^.+?@[^@]+?$/
    var re = /^\S+@\S+\.\S+$/;
    return re.test(email);
  }

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = event.target.value;
    setLocalEmail(newEmail)
    if (validEmail(newEmail)) {
      setValidEmailAddress(true);
      setEmail(newEmail);
    } else {
      setValidEmailAddress(false);
      setEmail("");
    }

    let _answers = answers;
    _answers.reporterContact = newEmail;
    setAnswers(_answers);
    // console.log(_answers);
  }

  const handleChangeIssueType = (event: SelectChangeEvent) => {
    setIssueType(event.target.value as string)
    let _answers = answers;
    _answers.issueType = event.target.value;
    setAnswers(_answers);
    // console.log(_answers);
  }

  const handleChangeLocation = (event: SelectChangeEvent) => {
    setLocation(event.target.value as string)
    let _answers = answers;
    _answers.location = event.target.value;
    setAnswers(_answers);
    // console.log(_answers);
  }

  const handleChangeValue = (event: React.ChangeEvent<HTMLInputElement>, key: string) => {
    const newValue = event.target.value;
    let _answers = answers;
    // @ts-ignore
    _answers[key] = newValue;
    setAnswers(_answers);
    console.log(_answers);
  }

  // const handleChangeBuilding = (event: SelectChangeEvent) => {
  //   setBuilding(event.target.value as string);
  //   let _answers = answers;
  //   _answers.contact.building = event.target.value;
  //   setAnswers(_answers);
  //   // console.log(_answers);
  // }
  //
  const handleBack = (section: number) => {
    window.scrollTo(0, 0);
    switch (section) {
      default:
        changeToSection(section - 1);
    }
  }

  const handleNext = (section: number) => {
    window.scrollTo(0, 0);
    switch (section) {
      default:
        changeToSection(section + 1);
    }
  }

  const handleSubmit = (section: number) => {
    window.scrollTo(0, 0);
    switch (section) {
      case 5:
        if (validEmailAddress) {
          beginSend();
          changeToSection(section + 1);
        } else {
          alert('Please enter a valid email address (make sure there are no trailing spaces).');
        }
        break;
    }
  }


  const handleAnswerQuestion = (section: number, answer: any) => {
    window.scrollTo(0, 0);
    let _answers = answers;
console.log(section,answer)
    switch (section) {

      case 3:
        _answers.urgency = answer;
        setAnswers(_answers);
        // changeToSection(section+1);
        break;
      // case 3:
      //   _answers.ownerTenant = answer;
      //   setAnswers(_answers);
      //
      //   if (answer === 'owner-occupier') {
      //     changeToSection(6);
      //   } else if (answer === 'landlord-self-managing') {
      //     changeToSection(5);
      //   } else if (answer === 'landlord-using-an-agent') {
      //     changeToSection(4);
      //   } else if (answer === 'property-manager-agent') {
      //     changeToSection(5);
      //   } else if (answer === 'tenant') {
      //     changeToSection(4); // tell; us y9our agent and then
      //   }
      //   break;
      // case 4:
      //   _answers.agent = answer;
      //   setAnswers(_answers);
      //   if (answers.ownerTenant === 'tenant') {
      //     changeToSection(6);
      //   } else {
      //     changeToSection(10);
      //   }
      //   break;
      // case 5:
      //   _answers.propertiesManaged = answer;
      //   setAnswers(_answers);
      //   if (answer === 'landlord-self-managing') {
      //     changeToSection(6);
      //   } else if (answer === 'property-manager-agent') {
      //     changeToSection(6);
      //   } else {
      //     changeToSection(6);
      //   }
      //   break;
      // case 6:
      //   _answers.maintenanceRequirements = answer;
      //   setAnswers(_answers);
      //   changeToSection(7);
      //   break;
      // case 7:
      //   _answers.optionalExtras = answer;
      //   setAnswers(_answers);
      //   // changeToSection(8);
      //   break;
      // case 8:
      //   _answers.other = answer;
      //   setAnswers(_answers);
      //   changeToSection(9);
      //   break;
      // case 9:
      //   _answers.contact = answer;
      //   setAnswers(_answers);
      //   changeToSection(10);
      //   break;
    }
    // console.log(answers);
  }

  return (
    <>
      <div className="section section__two">

        <div className='questionnaire-page__title-section-two'>


          <LinearProgress style={{width: '100%'}} variant="determinate" value={progress} />

          { section === 1 && (
            <>
              <h1>Questionnaire</h1>

              <p>Please use this form to fill out building issues at 180</p>

              <br/>

              <Button className="starling-button" color="inherit" variant="outlined" onClick={() => {handleNext(section)}}>Begin</Button>

            </>
          )}

          {/*qu 1*/}
          { section === 2 && (
            <>
              <h3>Details</h3>
              {/*<p>*/}
              {/*  (Bessborough House, Scott House, Ambrose House, Dawson House, Pearce House, Fladgate House, Halliday House, Faraday House).*/}
              {/*</p>*/}


              {/*<FormControl>*/}
              {/*  <TextField*/}
              {/*    defaultValue={answers.issueType}*/}
              {/*    className={`questionnaire-text-box`}*/}
              {/*    style={{color: 'white', borderColor: 'white', borderRadius: '5px', marginTop:'10px', width:'60vw', textAlign: 'left'}}*/}
              {/*    id="outlined-basic" label="Issue Type (e.g. Leak)" multiline={true} rows={1} variant="outlined" onChange={(e: any) => handleChangeValue(e, 'issueType')}*/}
              {/*  /><br/>*/}
              {/*</FormControl>*/}

              <>
                {/*<InputLabel id="test-select-label" shrink={true}>Building</InputLabel>*/}
                <Select
                  notched={true}
                  style={{borderColor: 'white', borderRadius: '5px', marginTop:'10px', width:'60vw', textAlign: 'left'}}
                  className="questionnaire-text-box-select"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={issueType}
                  label="Issue Type"
                  onChange={handleChangeIssueType}
                >
                  <MenuItem value={'leak'}>Leak</MenuItem>
                  <MenuItem value={'break'}>Break</MenuItem>
                  <MenuItem value={'other'}>Other</MenuItem>
                </Select>
              </>

              {/*<FormControl>*/}
              {/*  <TextField*/}
              {/*    defaultValue={answers.location}*/}
              {/*    className={`questionnaire-text-box`}*/}
              {/*    style={{color: 'white', borderColor: 'white', borderRadius: '5px', marginTop:'10px', width:'60vw', textAlign: 'left'}}*/}
              {/*    id="outlined-basic" label="Location (e.g. Downstairs Toilet)" multiline={true} rows={1} variant="outlined" onChange={(e: any) => handleChangeValue(e, 'location')}*/}
              {/*  /><br/>*/}
              {/*</FormControl>*/}

              <>
                {/*<InputLabel id="test-select-label" shrink={true}>Building</InputLabel>*/}
                <Select
                  notched={true}
                  style={{borderColor: 'white', borderRadius: '5px', marginTop:'10px', width:'60vw', textAlign: 'left'}}
                  className="questionnaire-text-box-select"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={location}
                  label="Location"
                  onChange={handleChangeLocation}
                >
                  <MenuItem value={'leak'}>Downstairs</MenuItem>
                  <MenuItem value={'break'}>Upstairs</MenuItem>
                  <MenuItem value={'other'}>Other</MenuItem>
                </Select>
              </>


              {/*<>*/}
              {/*  /!*<InputLabel id="test-select-label" shrink={true}>Building</InputLabel>*!/*/}
              {/*  <Select*/}
              {/*    notched={true}*/}
              {/*    style={{borderColor: 'white', borderRadius: '5px', marginTop:'10px', width:'60vw', textAlign: 'left'}}*/}
              {/*    className="questionnaire-text-box-select"*/}
              {/*    labelId="demo-simple-select-label"*/}
              {/*    id="demo-simple-select"*/}
              {/*    value={building}*/}
              {/*    label="Issue Type"*/}
              {/*    onChange={handleChangeIssueType}*/}
              {/*  >*/}
              {/*    <MenuItem value={'bessborough-house'}>Bessborough House</MenuItem>*/}
              {/*    <MenuItem value={'scott-house'}>Scott House</MenuItem>*/}
              {/*    <MenuItem value={'ambrose-house'}>Ambrose House</MenuItem>*/}
              {/*    <MenuItem value={'dawson-house'}>Dawson House</MenuItem>*/}
              {/*    <MenuItem value={'pearce-house'}>Pearce House</MenuItem>*/}
              {/*    <MenuItem value={'fladgate-house'}>Fladgate House</MenuItem>*/}
              {/*    <MenuItem value={'halliday-house'}>Halliday House</MenuItem>*/}
              {/*    <MenuItem value={'faraday-house'}>Faraday House</MenuItem>*/}
              {/*    <MenuItem value={'multiple'}>Multiple</MenuItem>*/}
              {/*  </Select>*/}
              {/*</>*/}

            <div className="questionnaire-navigation-buttons">
              {/*<p>After any initial issues have been resolved all labor costs going forward will be included within the subscription (we hope long-term to include parts also) Specifically where there is an issue that falls between the Heating and cooling unit HIU/CIU managed by equines we will make sure the issue is resolved and any remedial works that need to be undertaken after their visits will be done so at no additional cost to the client.  We also very aware that if your service takes place in December you may not be aware that there is an issue with the cooling for a number of months that if an issue occurs whilst you are still subscribed we will come and resolve it for no additional charge giving you peace of mind.</p>*/}
              {/*<Button color="inherit" variant="outlined" onClick={() => {changeToSection(3)}}>Begin</Button>*/}
              <Button className="questionnaire-back-button" color="inherit" variant="outlined" onClick={() => {handleBack(section)}}>Back</Button>

              {/*{ (answers.issueType !== '' && answers.location !== '') && (*/}
              {/*{ answers.location !== '' && (*/}
              <Button className="questionnaire-next-button" color="inherit" variant="outlined" onClick={() => {handleNext(section)}}>Next</Button>
              {/*)}*/}
            </div>
          </>
        )}

        {/*  /!*qu 2*!/*/}
          { section === 3 && (
            <>
              <h3>Urgency Level?</h3>

              <FormControl>
                {/*<FormLabel id="demo-radio-buttons-group-label"></FormLabel>*/}
                <RadioGroup
                  className="radio-group"
                  onChange={(e: any) => {handleAnswerQuestion(section, e.target.value)}}
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={answers.urgency}
                  name="radio-buttons-group"
                >
                  <FormControlLabel value="urgent" control={<Radio />} label="Urgent (Life Threatening)" />
                  <FormControlLabel value="medium" control={<Radio />} label="Medium Urgency (Could become Urgent)" />
                  <FormControlLabel value="trivial" control={<Radio />} label="Trivial (Will cause Annoyance)" />
                </RadioGroup>

              </FormControl>

              <div className="questionnaire-navigation-buttons">
                <Button className="questionnaire-back-button" color="inherit" variant="outlined" onClick={() => {handleBack(section)}}>Back</Button>

                {/*{ answers.urgency !== '' && (*/}
                <Button className="questionnaire-next-button" color="inherit" variant="outlined" onClick={() => {handleNext(section)}}>Next</Button>
                {/*)}*/}

              </div>

              {/*<p>After any initial issues have been resolved all labor costs going forward will be included within the subscription (we hope long-term to include parts also) Specifically where there is an issue that falls between the Heating and cooling unit HIU/CIU managed by equines we will make sure the issue is resolved and any remedial works that need to be undertaken after their visits will be done so at no additional cost to the client.  We also very aware that if your service takes place in December you may not be aware that there is an issue with the cooling for a number of months that if an issue occurs whilst you are still subscribed we will come and resolve it for no additional charge giving you peace of mind.</p>*/}

            </>
          )}


        {/*  /!*qu 3*!/*/}
          { section === 4 && (
            <>
              <h3>Description?</h3>
        {/*      <h3>What is the name of your property management company and what email address do you use to communicate with them most often.</h3>*/}

              <FormControl>
                <TextField
                  defaultValue={answers.description}
                  className={`questionnaire-text-box`}
                  style={{color: 'white', borderColor: 'white', borderRadius: '5px', marginTop:'10px', width:'60vw', textAlign: 'left'}}
                  id="outlined-basic" label="More details including any disruptions created" multiline={true} rows={4} variant="outlined" onChange={(e: any) => handleChangeValue(e, 'description')}
                /><br/>
              </FormControl>

              {/*<p>Please email any images or videos to: <a href="mailto:zak.butcher@thevinylfactory.com" target="_blank">zak.butcher@thevinylfactory.com</a></p>*/}

              <p>Upload image/video (demo functionality)</p>
              <CameraComponent />

        {/*      <FormControl>*/}
        {/*        /!*<FormLabel id="demo-radio-buttons-group-label"></FormLabel>*!/*/}
        {/*        <TextField*/}
        {/*          style={{borderColor: 'white', borderRadius: '5px', marginTop:'10px', width:'60vw', textAlign: 'left'}}*/}
        {/*          className={`questionnaire-text-box`}*/}
        {/*          // defaultValue={roseCount}*/}
        {/*          // inputProps={{ maxLength: 4 }}*/}
        {/*          label="Agent"*/}
        {/*          defaultValue={agent}*/}
        {/*          onChange={handleChangeAgent}*/}
        {/*        />*/}

        {/*        <TextField*/}
        {/*          style={{borderColor: 'white', borderRadius: '5px', marginTop:'10px', width:'60vw', textAlign: 'left'}}*/}
        {/*          className={`questionnaire-text-box ${validAgentEmailAddress ? 'green-input' : 'red-input'}`}*/}
        {/*          // defaultValue={roseCount}*/}
        {/*          // inputProps={{ maxLength: 4 }}*/}
        {/*          label="Agent Email"*/}
        {/*          value={localAgentEmail}*/}
        {/*          onChange={handleChangeAgentEmail}*/}
        {/*        />*/}

        {/*      </FormControl>*/}



              <div className="questionnaire-navigation-buttons">
                <Button className="questionnaire-back-button" color="inherit" variant="outlined" onClick={() => {handleBack(section)}}>Back</Button>

                {/*{ answers.ownerTenant !== '' && (*/}
                  <Button className="questionnaire-next-button" color="inherit" variant="outlined" onClick={() => {handleNext(section)}}>Next</Button>
                {/*)}*/}

              </div>

        {/*      /!*<p>After any initial issues have been resolved all labor costs going forward will be included within the subscription (we hope long-term to include parts also) Specifically where there is an issue that falls between the Heating and cooling unit HIU/CIU managed by equines we will make sure the issue is resolved and any remedial works that need to be undertaken after their visits will be done so at no additional cost to the client.  We also very aware that if your service takes place in December you may not be aware that there is an issue with the cooling for a number of months that if an issue occurs whilst you are still subscribed we will come and resolve it for no additional charge giving you peace of mind.</p>*!/*/}

            </>
          )}

        {/*/!*qu 4*!/*/}
        { section === 5 && (
          <>
            <h3>Reporter Details</h3>

            <FormControl>
              <TextField
                defaultValue={answers.reporterName}
                className={`questionnaire-text-box`}
                style={{color: 'white', borderColor: 'white', borderRadius: '5px', marginTop:'10px', width:'60vw', textAlign: 'left'}}
                id="outlined-basic" label="Reporter Name" multiline={true} rows={1} variant="outlined" onChange={(e: any) => handleChangeValue(e, 'reporterName')}
              /><br/>
            </FormControl>

            <FormControl>
              <TextField
                inputProps={{ style: { color: "white", borderColor: 'white' } }}
                style={{color: 'white', borderColor: 'white', borderRadius: '5px', marginTop:'10px', width:'60vw', textAlign: 'left'}}
                className={`questionnaire-text-box ${validEmailAddress ? 'green-input' : 'red-input'}`}
                // defaultValue={roseCount}
                // inputProps={{ maxLength: 4 }}
                required
                label="Reporter Contact Email"
                value={localEmail}
                onChange={handleChangeEmail}
              />
            </FormControl>

        {/*    <FormControl>*/}
        {/*      /!*<FormLabel id="demo-radio-buttons-group-label"></FormLabel>*!/*/}
        {/*      <RadioGroup*/}
        {/*        className="radio-group"*/}
        {/*        onChange={(e: any) => {handleAnswerQuestion(section, e.target.value)}}*/}
        {/*        aria-labelledby="demo-radio-buttons-group-label"*/}
        {/*        defaultValue={answers.propertiesManaged}*/}
        {/*        name="radio-buttons-group"*/}
        {/*      >*/}
        {/*        <FormControlLabel value="1" control={<Radio />} label="1" />*/}
        {/*        <FormControlLabel value="2to5" control={<Radio />} label="2 to 5" />*/}
        {/*        <FormControlLabel value="5to15" control={<Radio />} label="5 to 15" />*/}
        {/*        <FormControlLabel value="15to30" control={<Radio />} label="15 to 30" />*/}
        {/*        <FormControlLabel value="30to50" control={<Radio />} label="30 to 50" />*/}
        {/*        <FormControlLabel value="50+" control={<Radio />} label="50+" />*/}
        {/*        <FormControlLabel value="undisclosed" control={<Radio />} label="Prefer not to say" />*/}
        {/*      </RadioGroup>*/}
        {/*    </FormControl>*/}

        <div className="questionnaire-navigation-buttons">
          {/*<p>After any initial issues have been resolved all labor costs going forward will be included within the subscription (we hope long-term to include parts also) Specifically where there is an issue that falls between the Heating and cooling unit HIU/CIU managed by equines we will make sure the issue is resolved and any remedial works that need to be undertaken after their visits will be done so at no additional cost to the client.  We also very aware that if your service takes place in December you may not be aware that there is an issue with the cooling for a number of months that if an issue occurs whilst you are still subscribed we will come and resolve it for no additional charge giving you peace of mind.</p>*/}
          <Button className="questionnaire-back-button" color="inherit" variant="outlined" onClick={() => {handleBack(section)}}>Back</Button>
          <Button className="questionnaire-next-button" color="inherit" variant="outlined" onClick={() => {handleSubmit(section)}}>Submit</Button>
        </div>

        {/*    /!*<p>After any initial issues have been resolved all labor costs going forward will be included within the subscription (we hope long-term to include parts also) Specifically where there is an issue that falls between the Heating and cooling unit HIU/CIU managed by equines we will make sure the issue is resolved and any remedial works that need to be undertaken after their visits will be done so at no additional cost to the client.  We also very aware that if your service takes place in December you may not be aware that there is an issue with the cooling for a number of months that if an issue occurs whilst you are still subscribed we will come and resolve it for no additional charge giving you peace of mind.</p>*!/*/}

          </>
        )}


        {/*/!*qu 5*!/*/}
        {/*{ section === 6 && (*/}
        {/*  <>*/}
        {/*    <h3>What do you need from a maintenance service?</h3>*/}

        {/*    <FormControl>*/}
        {/*      <RadioGroup*/}
        {/*        className={`radio-group radio-group--more-padding`}*/}
        {/*        onChange={(e: any) => {handleAnswerQuestion(section, e.target.value)}}*/}
        {/*        aria-labelledby="demo-radio-buttons-group-label"*/}
        {/*        defaultValue={answers.maintenanceRequirements}*/}
        {/*        name="radio-buttons-group"*/}
        {/*      >*/}
        {/*        <FormControlLabel value="minimum-covered-maintenance" control={<Radio />} label="To keep costs down with the absolute minimum maintenance covered." />*/}
        {/*        <FormControlLabel value="basics-covered" control={<Radio />} label="To cover all the basics, including regular and preventative maintenance to keep my property in good condition." />*/}
        {/*        <FormControlLabel value="enhanced-service" control={<Radio />} label="To keep my property to a high standard, and improve my experience of living there with an enhanced service." />*/}
        {/*        <FormControlLabel value="additional-support" control={<Radio />} label="A premium service, so I don't have to think about my property. Building on an enhanced service with more regular checks to keep everything in the property up to standard, identify issues and give additional support for my/my resident's queries." />*/}
        {/*      </RadioGroup>*/}

        {/*    </FormControl>*/}

        {/*    <div className="questionnaire-navigation-buttons">*/}
        {/*      <Button className="questionnaire-back-button" color="inherit" variant="outlined" onClick={() => {handleBack(section)}}>Back</Button>*/}
        {/*      { answers.maintenanceRequirements !== '' && (*/}
        {/*        <Button className="questionnaire-next-button" color="inherit" variant="outlined" onClick={() => {handleNext(section)}}>Next</Button>*/}
        {/*      )}*/}
        {/*    </div>*/}

        {/*    /!*<p>After any initial issues have been resolved all labor costs going forward will be included within the subscription (we hope long-term to include parts also) Specifically where there is an issue that falls between the Heating and cooling unit HIU/CIU managed by equines we will make sure the issue is resolved and any remedial works that need to be undertaken after their visits will be done so at no additional cost to the client.  We also very aware that if your service takes place in December you may not be aware that there is an issue with the cooling for a number of months that if an issue occurs whilst you are still subscribed we will come and resolve it for no additional charge giving you peace of mind.</p>*!/*/}

        {/*  </>*/}
        {/*)}*/}

        {/*/!*qu 6*!/*/}
        {/*{ section === 7 && (*/}
        {/*  <>*/}
        {/*    <h3>What other items would you like to see included in the regular maintenance package (examples might be: cleaning taps and shower heads)?</h3>*/}

        {/*    <FormControl>*/}
        {/*      <TextField*/}
        {/*        defaultValue={answers.optionalExtras}*/}
        {/*        className={`questionnaire-text-box`}*/}
        {/*        style={{color: 'white', borderColor: 'white', borderRadius: '5px', marginTop:'10px', width:'60vw', textAlign: 'left'}}*/}
        {/*        id="outlined-basic" label="Please state." multiline={true} rows={4} variant="outlined" onChange={(e: any) => handleChangeValue(e, 'optionalExtras')}*/}
        {/*      /><br/>*/}
        {/*    </FormControl>*/}

        {/*    <div className="questionnaire-navigation-buttons">*/}
        {/*      <Button className="questionnaire-back-button" color="inherit" variant="outlined" onClick={() => {handleBack(section)}}>Back</Button>*/}
        {/*      /!*{ answers.optionalExtras !== '' && (*!/*/}
        {/*        <Button className="questionnaire-next-button" color="inherit" variant="outlined" onClick={() => {handleNext(section)}}>Next</Button>*/}
        {/*      /!*)}*!/*/}
        {/*    </div>*/}

        {/*    /!*<p>After any initial issues have been resolved all labor costs going forward will be included within the subscription (we hope long-term to include parts also) Specifically where there is an issue that falls between the Heating and cooling unit HIU/CIU managed by equines we will make sure the issue is resolved and any remedial works that need to be undertaken after their visits will be done so at no additional cost to the client.  We also very aware that if your service takes place in December you may not be aware that there is an issue with the cooling for a number of months that if an issue occurs whilst you are still subscribed we will come and resolve it for no additional charge giving you peace of mind.</p>*!/*/}

        {/*  </>*/}
        {/*)}*/}

        {/*/!*qu 7*!/*/}
        {/*{ section === 8 && (*/}
        {/*  <>*/}
        {/*    <h3>Are there any one-time maintenance problems you have found hard to solve, that we can help with?</h3>*/}

        {/*    <FormControl>*/}
        {/*      /!*<FormLabel id="demo-radio-buttons-group-label"></FormLabel>*!/*/}
        {/*      <TextField*/}
        {/*        defaultValue={answers.other}*/}
        {/*        className={`questionnaire-text-box`}*/}
        {/*        style={{borderColor: 'white', borderRadius: '5px', marginTop:'10px', width:'60vw', textAlign: 'left'}}*/}
        {/*        id="outlined-basic" label="Please state." variant="outlined" multiline={true} rows={4} onChange={(e: any) => handleChangeValue(e, 'other')}*/}
        {/*      /><br/>*/}
        {/*    </FormControl>*/}

        {/*    <div className="questionnaire-navigation-buttons">*/}
        {/*      <Button className="questionnaire-back-button" color="inherit" variant="outlined" onClick={() => {handleBack(section)}}>Back</Button>*/}
        {/*      /!*{ answers.other !== '' && (*!/*/}
        {/*        <Button className="questionnaire-next-button" color="inherit" variant="outlined" onClick={() => {handleNext(section)}}>Next</Button>*/}
        {/*      /!*)}*!/*/}
        {/*    </div>*/}

        {/*    /!*<p>After any initial issues have been resolved all labor costs going forward will be included within the subscription (we hope long-term to include parts also) Specifically where there is an issue that falls between the Heating and cooling unit HIU/CIU managed by equines we will make sure the issue is resolved and any remedial works that need to be undertaken after their visits will be done so at no additional cost to the client.  We also very aware that if your service takes place in December you may not be aware that there is an issue with the cooling for a number of months that if an issue occurs whilst you are still subscribed we will come and resolve it for no additional charge giving you peace of mind.</p>*!/*/}

        {/*  </>*/}
        {/*)}*/}

        {/*/!*{ section === 9 && (*!/*/}
        {/*/!*  <>*!/*/}
        {/*/!*    <h3>Contact Options</h3>*!/*/}

        {/*/!*    <FormControl>*!/*/}
        {/*/!*      <FormLabel id="demo-radio-buttons-group-label">We are just about to launch our products on phase 1 of the development and we would love to get in contact with you to let you know that we are doing a beta launch And also when we launch the product more widely.  In order to contact you we would please provide your email address and tick the following options.*!/*/}
        {/*/!*      </FormLabel>*!/*/}
        {/*/!*      <RadioGroup*!/*/}
        {/*/!*        onChange={(e: any) => {handleAnswerQuestion(section, e.target.value)}}*!/*/}
        {/*/!*        aria-labelledby="demo-radio-buttons-group-label"*!/*/}
        {/*/!*        defaultValue={answers.contactOptions}*!/*/}
        {/*/!*        name="radio-buttons-group"*!/*/}
        {/*/!*      >*!/*/}
        {/*/!*        <FormControlLabel value="beta-contact" control={<Radio />} label="Please contact me when you do your beta launch." />*!/*/}
        {/*/!*        <FormControlLabel value="full-contact" control={<Radio />} label="Please contact me when you do your full launch" />*!/*/}
        {/*/!*        <FormControlLabel value="reach-out" control={<Radio />} label="Please reach out to me for any additional questions you have to help improve the product" />*!/*/}
        {/*/!*        <FormControlLabel value="update-me" control={<Radio />} label="Please keep me updated with any news relating to your company" />*!/*/}
        {/*/!*        <FormControlLabel value="skip-me" control={<Radio />} label="Skip question" />*!/*/}
        {/*/!*        /!*<FormControlLabel value="other" control={<Radio />} label="50+" />*!/*!/*/}
        {/*/!*      </RadioGroup>*!/*/}
        {/*/!*    </FormControl>*!/*/}

        {/*/!*    <div className="questionnaire-navigation-buttons">*!/*/}
        {/*/!*      <Button className="questionnaire-back-button" color="inherit" variant="outlined" onClick={() => {handleBack(section)}}>Back</Button>*!/*/}
        {/*/!*      <Button className="questionnaire-next-button" color="inherit" variant="outlined" onClick={() => {handleNext(section)}}>Next</Button>*!/*/}
        {/*/!*    </div>*!/*/}

        {/*/!*    /!*<p>After any initial issues have been resolved all labor costs going forward will be included within the subscription (we hope long-term to include parts also) Specifically where there is an issue that falls between the Heating and cooling unit HIU/CIU managed by equines we will make sure the issue is resolved and any remedial works that need to be undertaken after their visits will be done so at no additional cost to the client.  We also very aware that if your service takes place in December you may not be aware that there is an issue with the cooling for a number of months that if an issue occurs whilst you are still subscribed we will come and resolve it for no additional charge giving you peace of mind.</p>*!/*!/*/}

        {/*/!*  </>*!/*/}
        {/*/!*)}*!/*/}

        {/*/!* thanks *!/*/}
        {/*{ section === 9 && (*/}
        {/*  <>*/}
        {/*    <h3>Contact Details</h3>*/}

        {/*    { answers.resident === false && (*/}
        {/*      <p>We are really excited that you have been sent this survey but were not quite ready to launch across other*/}
        {/*        phases/buildings just yet.<br/><br/>*/}
        {/*        Please let us know the name of the building you are living in and your email address and we will contact you when your building goes live. It is likely that we will launch in the building with the greatest interest so please let others in your building know by sharing this survey!*/}
        {/*      </p>*/}
        {/*    )}*/}


        {/*    { answers.resident === true && (*/}
        {/*      <p>Thank you for taking the time to complete this questionnaire. We would love to stay in touch and let you know about the early bird and any future launches.*/}
        {/*        (By providing your details, you agree to let Starling Red contact you with details about launches of their service and updates - we will keep communications to a minimum.)</p>*/}
        {/*    )}*/}

        {/*    /!*<p>Do you live in phase 1 of the Battersea Power Station?<br/>*!/*/}
        {/*    /!*  (Bessborough House, Scott House, Ambrose House, Dawson House, Pearce House, Floodgate House, Haliday House, Faraday House).*!/*/}
        {/*    /!*</p>*!/*/}

        {/*    /!*<ButtonGroup variant="outlined" aria-label="outlined button group">*!/*/}
        {/*    /!*  <Button onClick={() => {handleAnswerQuestion(3, 'yes')}}>Yes</Button>*!/*/}
        {/*    /!*  <Button onClick={() => {handleAnswerQuestion(3, 'no')}}>No</Button>*!/*/}
        {/*    /!*</ButtonGroup>*!/*/}
        {/*    <br/>*/}

        {/*    <TextField*/}
        {/*      inputProps={{ style: { color: "white", borderColor: 'white' } }}*/}
        {/*      style={{color: 'white', borderColor: 'white', borderRadius: '5px', marginTop:'10px', width:'60vw', textAlign: 'left'}}*/}
        {/*      className={`questionnaire-text-box ${validEmailAddress ? 'green-input' : 'red-input'}`}*/}
        {/*      // defaultValue={roseCount}*/}
        {/*      // inputProps={{ maxLength: 4 }}*/}
        {/*      required*/}
        {/*      label="Email"*/}
        {/*      value={localEmail}*/}
        {/*      onChange={handleChangeEmail}*/}
        {/*    />*/}

        {/*    { answers.resident === false && (*/}
        {/*      <FormControl>*/}
        {/*        /!*<FormLabel id="demo-radio-buttons-group-label"></FormLabel>*!/*/}
        {/*        <TextField*/}
        {/*          defaultValue={answers.contact.building}*/}
        {/*          className={`questionnaire-text-box`}*/}
        {/*          style={{borderColor: 'white', borderRadius: '5px', marginTop:'10px', width:'60vw', textAlign: 'left'}}*/}
        {/*          id="outlined-basic" label="Building." variant="outlined" multiline={true} rows={4} onChange={(e: any) => handleChangeBuilding(e)}*/}
        {/*        /><br/>*/}
        {/*      </FormControl>*/}
        {/*    )}*/}

        {/*    /!*<TextField value={email} onChange={(e: any) => {handleChangeEmail(e)}} id="outlined-basic" label="Email Address" variant="outlined" className="questionnaire-text-box" /><br/>*!/*/}
        {/*    { answers.resident === true && (*/}
        {/*      <>*/}
        {/*        /!*<InputLabel id="test-select-label" shrink={true}>Building</InputLabel>*!/*/}
        {/*        <Select*/}
        {/*          notched={true}*/}
        {/*          style={{borderColor: 'white', borderRadius: '5px', marginTop:'10px', width:'60vw', textAlign: 'left'}}*/}
        {/*          className="questionnaire-text-box-select"*/}
        {/*          labelId="demo-simple-select-label"*/}
        {/*          id="demo-simple-select"*/}
        {/*          value={building}*/}
        {/*          label="Building"*/}
        {/*          onChange={handleChangeBuilding}*/}
        {/*        >*/}
        {/*          <MenuItem value={'bessborough-house'}>Bessborough House</MenuItem>*/}
        {/*          <MenuItem value={'scott-house'}>Scott House</MenuItem>*/}
        {/*          <MenuItem value={'ambrose-house'}>Ambrose House</MenuItem>*/}
        {/*          <MenuItem value={'dawson-house'}>Dawson House</MenuItem>*/}
        {/*          <MenuItem value={'pearce-house'}>Pearce House</MenuItem>*/}
        {/*          <MenuItem value={'fladgate-house'}>Fladgate House</MenuItem>*/}
        {/*          <MenuItem value={'halliday-house'}>Halliday House</MenuItem>*/}
        {/*          <MenuItem value={'faraday-house'}>Faraday House</MenuItem>*/}
        {/*          <MenuItem value={'multiple'}>Multiple</MenuItem>*/}
        {/*        </Select>*/}
        {/*      </>*/}
        {/*    )}*/}

        {/*    <div className="questionnaire-navigation-buttons">*/}
        {/*      /!*<p>After any initial issues have been resolved all labor costs going forward will be included within the subscription (we hope long-term to include parts also) Specifically where there is an issue that falls between the Heating and cooling unit HIU/CIU managed by equines we will make sure the issue is resolved and any remedial works that need to be undertaken after their visits will be done so at no additional cost to the client.  We also very aware that if your service takes place in December you may not be aware that there is an issue with the cooling for a number of months that if an issue occurs whilst you are still subscribed we will come and resolve it for no additional charge giving you peace of mind.</p>*!/*/}
        {/*      <Button className="questionnaire-back-button" color="inherit" variant="outlined" onClick={() => {handleBack(section)}}>Back</Button>*/}
        {/*      <Button className="questionnaire-next-button" color="inherit" variant="outlined" onClick={() => {handleSubmit(section)}}>Submit</Button>*/}
        {/*    </div>*/}
        {/*  </>*/}
        {/*)}*/}

        { section === 6 && (
          <>
            <h3>Thank you for continuing to help make 180 Studios a great place to be!</h3>

            {/*<p>For us to reach critical mass, we need to reach as many residents as possible. So if you know residents or agents who might be interested - please do forward this on to them.<br/><br/>The survey will close Sunday 5 March.<br/>*/}
            {/*  <br/>*/}
            {/*  <a href="https://starlingred.unegma.click">https://starlingred.unegma.click</a>*/}
            {/*</p>*/}


            {/*<p>After any initial issues have been resolved all labor costs going forward will be included within the subscription (we hope long-term to include parts also) Specifically where there is an issue that falls between the Heating and cooling unit HIU/CIU managed by equines we will make sure the issue is resolved and any remedial works that need to be undertaken after their visits will be done so at no additional cost to the client.  We also very aware that if your service takes place in December you may not be aware that there is an issue with the cooling for a number of months that if an issue occurs whilst you are still subscribed we will come and resolve it for no additional charge giving you peace of mind.</p>*/}

          </>
        )}

        </div>
      </div>

      {/*<div className="section home-section-three">*/}

      {/*  <img className='random-queen' src={`${process.env.REACT_APP_ASSETS_URL}/random_queen.jpeg`} />*/}

      {/*</div>*/}
    </>
  )
}

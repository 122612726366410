import {Button, createStyles, makeStyles, Modal, Theme, Typography, Box} from "@mui/material";
import React from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

export default function InfoModal ({ EMAIL, showInfoModal, setShowInfoModal }: any) {

  const handleClose = () => {
    setShowInfoModal(false);
  };

  const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    maxWidth: '90vw',
    minWidth: '85vw',
    maxHeight: '90vh',
    // minHeight: '85vh',
    overflow: 'scroll',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  // todo add /info to url when opening modal

  return (
    <Modal
      open={showInfoModal}
      onClose={handleClose}
      // aria-labelledby="simple-modal-title"
      // aria-describedby="simple-modal-description"
    >
      <Box component="div" sx={modalStyle} className="info-modal">
        <HighlightOffIcon className="closeModalButton" onClick={() => { setShowInfoModal(false)}}/>
        <Typography variant="h3" className="blacks-orange">Credits</Typography>

        <p style={{color: 'white'}}><a style={{color: 'white'}} href="https://sketchfab.com/3d-models/power-station-21df3f500f8649ea8657aab89fd78050" target="_blank">Battersea Power Station model</a></p>

        {/*<Typography className="address-info">{process.env.REACT_APP_ADDRESS}</Typography>*/}
        {/*/!*<Typography className="white"><a href={`mailto:${process.env.REACT_APP_CONTACT}`}>{process.env.REACT_APP_CONTACT}</a></Typography>*!/*/}

        {/*<h2 className="blacks-orange">Contact Details</h2>*/}
        {/*<p>*/}
        {/*  <a className="contact-email" href={`mailto:${EMAIL}`}>For Events and Membership Enquiries</a><br/>*/}
        {/*  /!*<a className="contact-email" href={`mailto:victoria@cc-gbl.io`}>For Membership Enquiries</a>*!/*/}
        {/*</p>*/}

        {/*<br/>*/}

        {/*<iframe*/}
        {/*  src={process.env.REACT_APP_GOOGLE_MAP_URL}*/}
        {/*  width="100%" height="auto" className="info-map" loading="lazy"*/}
        {/*  referrerPolicy="no-referrer-when-downgrade">*/}
        {/*</iframe>*/}
      </Box>
    </Modal>
  )
}
